.add-blood-test-container {
  padding: 24px;
}

.add-blood-test-content {
  max-width: 800px;
  margin: 0 auto;
}

.add-blood-test-form-container {
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
}

.search-container {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
  outline: none;
}

.search-input:focus {
  border-color: #243b86;
}

.loading-indicator {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-result-item {
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-result-item:hover {
  background-color: #f5f5f5;
}

.address-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
  resize: vertical;
  min-height: 100px;
  outline: none;
  font-family: inherit;
}

.address-input:focus {
  border-color: #243b86;
}

.add-address-link {
  background: none;
  border: none;
  color: #243b86;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 24px;
  text-decoration: none;
  display: block;
}

.add-address-link:hover {
  text-decoration: underline;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
}

.button {
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
  border: none;
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button-primary {
  background-color: #243b86;
  color: white;
}

.button-primary:hover:not(:disabled) {
  background-color: #1a2a6c;
}

.button-secondary {
  background-color: transparent;
  border: 1px solid #ddd;
  color: #666;
}

.button-secondary:hover {
  border-color: #243b86;
  color: #243b86;
}
