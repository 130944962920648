.articleListSideBar {
  min-width: 200px;
  max-width: 200px;
  background: #fff;
  color: #333;
  transition: all 0.3s;
  box-shadow: 3px 0 5px #ededed;
  position: fixed;
  height: 100vh;
  z-index: 999;
}
.articleListSideBar.sidebar-close {
  min-width: 60px;
  max-width: 60px;
  margin-left: 0;
}
.sideBarImg {
  position: absolute;
  right: -26px;
  cursor: pointer;
}
.sidebarLogo {
  border-bottom: 1px dashed var(--inputBorderColor);
  margin-bottom: 9px;
  background-color: #fff;
}
.sidebarLogo img.logo {
  padding: 20px 30px;
  width: 100%;
}

.articleListSideBar.sidebar-active .sm-logo,
.articleListSideBar.sidebar-close .logo,
.articleListSideBar.sidebar-close .sideLinks .sidebarNavText {
  display: none;
}
.articleListSideBar.sidebar-close .sm-logo {
  display: block;
}
.articleListSideBar.sidebar-close .sidebarLogo {
  width: 60px;
  height: 60px;
  border-bottom: 1px dashed var(--inputBorderColor);
  align-items: center;
  justify-content: center;
}
.articleListSideBar.sidebar-close .sm-logo {
  width: 80%;
}
.sidebarScreen .sideLinks {
  gap: 10px;
  padding: 15px 10px;
  align-items: center;
  width: 100%;
}
.articleListSideBar.sidebar-close .sidebarScreen .sideLinks {
  padding: 15px 15px;
}

.sidebarScreen .sideBarText {
  font-size: var(--normalFontMd);
  line-height: 20px;
  color: var(--lightBlack);
}
.sidebarScreen a {
  font-size: var(--normalFontMd);
  color: var(--lightBlack);
  display: flex;
}
.sidebarScreen a:focus {
  text-decoration: none;
}
.sidebarScreen a:hover,
.sidebarScreen a.activeLink {
  background-color: var(--primaryLighter);
  color: var(--themeDarkColor);
}
.sidebarScreen a.activeLink {
  font-weight: var(--semiBoldWeight);
}

/* SideBar Arrow Css*/
.side_switcher_bar .material-icons::before {
  content: '\e5cb';
}
.bodyActive .side_switcher_bar .material-icons::before {
  content: '\e409';
}
.side_switcher_bar {
  position: absolute;
  right: -16px;
  box-shadow: 3px 0 5px #ededed;
  z-index: -1;
}
.side_switcher_bar .material-icons {
  background: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  height: 50px;
  font-size: 20px;
  font-family: 'Material Icons';
  color: #595959;
}
.side_switcher_bar::before,
.side_switcher_bar::after {
  content: '';
  right: 2px;
  background: #fff;
  width: 17px;
  height: 19px;
  cursor: pointer;
  display: block;
  position: absolute;
}
.side_switcher_bar::before {
  top: -6px;
  transform: rotate(294deg);
  border-radius: 0 0 5px 0;
}
.side_switcher_bar::after {
  bottom: -6px;
  transform: rotate(-294deg);
  border-radius: 0 5px 0px 0;
}
/* SideBar Arrow Css*/

/* side dropdown menu*/
.sideLinks-submenu .rs-dropdown {
  display: flex;
  width: 100%;
  flex-grow: 1;
  justify-content: space-between;
  padding-right: 10px;
}
.sideLinks-submenu .rs-dropdown button {
  width: 200px;
  text-align: left;
  padding: 15px 20px 15px 50px !important;
  margin-left: -50px;
  display: flex;
  justify-content: flex-start;
}
.bodyActive .sideLinks-submenu .rs-dropdown button {
  width: 100%;
  text-align: left;
  left: -9px;
  padding: 0 30px;
}
.sidebarScreen .sideLinks-submenu .rs-btn-default svg {
  top: 13px;
}

.sidebarScreen .sideLinks-submenu {
  padding: 0 20px;
}
.bodyActive .sidebarScreen .sideLinks-submenu {
  padding: 15px 20px;
}
.sidebarScreen .sideLinks-submenu:hover .material-icons {
  position: relative;
  z-index: 10;
}
.bodyActive .sidebarScreen .sideLinks-submenu:hover .material-icons {
  z-index: 1;
}

/* side dropdown menu colour*/
.sideLinks-submenu > .material-icons,
.sideLinks-submenu .rs-dropdown-toggle,
.sidebarScreen .sideLinks-submenu .sideBarText,
.bodyActive .sidebarScreen .sideLinks-submenu:hover .material-icons {
  color: var(--lightBlack);
}
.bodyActive .sideLinks-submenu .sidebarScreen .sideLinks-submenu:hover button,
.sidebarScreen .sideLinks-submenu:hover button,
.bodyActive .sidebarScreen .sideLinks-submenu:hover {
  background-color: var(--primaryLighter) !important;

  border-radius: 0;
}

.side_switcher_bar .sideLinks-submenu .material-icons,
.sidebarScreen .sideLinks-submenu:hover .material-icons {
  color: var(--themeDarkColor);
}
/* side dropdown menu colour*/

@media (max-width: 768px) {
  .new-menu .new-menu-items {
    padding-left: 0px;
  }

  .articleListSideBar {
    margin-left: 0;
    min-width: 60px;
    max-width: 60px;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 999;
  }
  .articleListSideBar .m-logo {
    display: block;
    display: flex;
    padding: 0;
  }
  .articleListSideBar.sidebar-close {
    margin-left: 0;
    min-width: 200px;
    max-width: 200px;
  }
  .articleListSideBar.sidebar-active .sidebarLogo .logo {
    width: 90%;
  }
  .articleListSideBar.sidebar-active .sidebarLogo .sm-logo {
    width: 80%;
  }
  .articleListSideBar.sidebar-close .sm-logo,
  .articleListSideBar.sidebar-active .logo,
  .articleListSideBar.sidebar-active .sideLinks .sidebarNavText {
    display: none;
  }
  .articleListSideBar.sidebar-active .sm-logo,
  .articleListSideBar.sidebar-close .logo,
  .articleListSideBar.sidebar-close .sideLinks .sidebarNavText {
    display: block;
  }
  .articleListSideBar.sidebar-active .sidebarLogo {
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
  }
  .articleListSideBar.sidebar-close .sidebarLogo {
    width: 200px;
  }
  .sideLinks-submenu .rs-dropdown button {
    width: 120px;
    padding: 15px 18px !important;
    padding-left: 70px !important;
    margin-left: -108px;
    opacity: 0;
  }
  .sideLinks-submenu .rs-dropdown button:hover {
    background-color: var(--primaryLighter);
  }
  .bodyActive .sideLinks-submenu .rs-dropdown button {
    width: 200px;
    padding: 15px 18px !important;
    padding-left: 50px !important;
    margin-left: -41px;
    opacity: 1;
    top: -24px;
  }
  .bodyActive .sideLinks-submenu .rs-dropdown button:hover {
    background: none !important;
  }
}

.inactive-icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  transition: filter 0s ease; /* Smooth transition for hover effect */
}

.sideBarText:hover .inactive-icon,
.sideBarText .inactive-icon:hover {
  filter: none; /* Remove color filter on hover */
}

.active-icon {
  filter: none; /* Active state with no filter */
}

.new-menu-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: white;
}

.auto-rotate {
  margin-left: auto;
  transition: transform 0.2s ease;
}

.auto-rotate.expanded {
  transform: rotate(180deg);
}

.new-menu .new-menu-items {
  padding-left: 20px;
}

.articleListSideBar.sidebar-close .new-menu .new-menu-items {
  padding-left: 0px;
}

.new-menu {
  background-color: rgb(234, 111, 84);
  width: '100%';
}

.articleListSideBar.sidebar-active .new-menu-container {
  padding-left: 10px;
  padding-right: 5px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

.iconColor {
  color: white;
}
